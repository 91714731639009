<template>
    <div :id="`add-question-${itemIndex}`" class="my-2 mx-4 add-item" style="z-index: 15">
        <div ref="addItemDropdown" class="d-flex rounded p-2 border-none align-items-center widget-drawer">
            <div class="flex-grow-1">
                <div class="dropdown b-dropdown btn-group d-flex" @mouseover="hovered = true" @mouseleave="hovered = false">
                    <button
                        type="button"
                        class="btn btn-outline-secondary btn-block btn-sm add-item-label"
                        :class="{ hover: hovered }"
                        @click="openAddItemModal"
                    >
                        <AddItemDropdownLabel :icon-key="buttonData.key" :label="buttonData.name" />
                    </button>
                    <button
                        aria-haspopup="menu"
                        aria-expanded="false"
                        type="button"
                        class="btn dropdown-toggle btn-outline-secondary btn-sm dropdown-toggle-split add-item-dropdown-toggle"
                        tabindex="-1"
                        @click="openAddItemModal"
                    >
                        <span class="sr-only">Toggle dropdown</span>
                    </button>
                </div>
            </div>
            <div class="ml-2">
                <b-button
                    v-b-tooltip.hover
                    class="add-button"
                    variant="success"
                    size="sm"
                    title="Shortcut: Ctrl + Enter"
                    @click.stop="addItem"
                >
                    + Add
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { element_types } from '../../objects/Document'
import addItemMap from '../../helpers/AddItemMap'
import { EventBus } from '../../common/EventBus'
import { mixin as clickaway } from 'vue-clickaway'
import { toTitleCase } from '../../helpers/stringUtils'
import AddItemDropdownLabel from './AddItemDropdownLabel.vue'

export default {
    name: 'AddItem',
    components: { AddItemDropdownLabel },
    mixins: [clickaway],
    props: {
        itemIndex: {
            type: Number,
            default: 0,
        },
        item: {
            type: Object,
            default: () => {},
        },
        openCloseMainMenu: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [],
            activities: [],
            selectedItem: null,
            popOverAtParent: false,
            resetTopInterval: null,
            hovered: false,
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            documentTypes: 'document/allActivities',
            documentItems: 'document/documentItems',
            currentWidget: 'document/currentWidget',
            selected: 'document/selected',
            persistAction: 'document/persistAction',
            defaultSelectedWidget: 'abtests/defaultSelectedWidget',
            isWorksheet: 'document/isWorksheet',
        }),
        defaultWidget() {
            return this.defaultSelectedWidget ?? 'instruction'
        },
        allOptions() {
            return [...this.options[0].items, ...this.options[1].items]
        },
        buttonData() {
            return this.selected ? this.allOptions.find((option) => option.key === this.selected) : this.selectedItem
        },
    },
    watch: {
        defaultWidget: {
            handler() {
                this.setDefaultWidget()
            },
        },
        currentWidget: {
            async handler(val) {
                if (!this.isWorksheet) {
                    return
                }

                //If a non-header item is active
                if (val.focusedItem) {
                    this.selectedItem = {
                        name: this.allOptions.find((option) => option.key === addItemMap[val.focusedItem.type])?.name,
                        key: addItemMap[val.focusedItem.type],
                    }
                }

                //If the header item is active
                if (val.openHeader) {
                    this.selectedItem = {
                        name: this.allOptions.find((option) => option.key === this.defaultWidget).name,
                        key: this.defaultWidget,
                    }
                }

                //If all items including header are closed.
                if (!val.focusedItem && !val.openHeader) {
                    this.selectedItem = !this.item
                        ? {
                              name: this.allOptions.find((option) => option.key === this.defaultWidget).name,
                              key: this.defaultWidget,
                          }
                        : {
                              name: this.allOptions.find((option) => option.key === addItemMap[this.item.type]).name,
                              key: addItemMap[this.item.type],
                          }
                }

                await this.$store.dispatch('document/setSelected', {
                    selected: this.selectedItem.key,
                })
            },
            deep: true,
        },
        openCloseMainMenu: {
            handler(val) {
                if (val === true && this.$refs.select) {
                    this.$refs.select.open = false
                }
            },
        },
    },
    async created() {
        this.setItemOptions()
        this.setDefaultWidget()

        EventBus.$on('keyboard-event-add-item', await this.handleAddItemEvent)
    },
    methods: {
        toTitleCase,
        setDefaultWidget() {
            if (!this.documentItems.length || !this.currentWidget.focusedItem) {
                this.selectedItem = {
                    name: this.allOptions.find((option) => option.key === this.defaultWidget).name,
                    key: this.defaultWidget,
                }
            } else {
                if (this.item?.type) {
                    this.selectedItem = {
                        name: this.allOptions.find((option) => option.key === addItemMap[this.item.type]).name,
                        key: addItemMap[this.item.type],
                    }
                }
            }
        },
        async addItem() {
            let index = this.itemIndex === undefined ? 0 : this.itemIndex + 1

            await this.$store.dispatch('document/setSelected', {
                selected: this.selectedItem.key,
            })

            await this.$store.dispatch('document/pushNewItemWithTypeAt', {
                index,
            })

            this.$nextTick(async () => {
                if (this.isLoggedIn) {
                    await this.$store.dispatch(this.persistAction)
                }
                await this.$store.dispatch('document/scaleDocument', false)
                this.$emit('added', { id: this.documentItems[index]?.id })
            })

            this.goto(`preview-${this.documentItems[index]?.id}`)
        },
        goto(element) {
            this.$forceNextTick(() => {
                const elem = document.getElementById(element)

                if (!elem) return

                if (this.isInViewport(elem)) return

                let anchorSelector = this.getAnchor(element)

                const anchor = document.getElementById(anchorSelector)
                if (anchor) {
                    anchor.scrollIntoView({
                        behavior: 'smooth',
                    })
                }
            }, 1000)
        },
        isInViewport(element) {
            const rect = element.getBoundingClientRect()
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            )
        },
        getAnchor(ref) {
            if (ref === 'docHeader') {
                return 'anchor-docHeader'
            }

            if (ref === 'instructions' || ref === 'bingo_words') {
                return `anchor-${ref}-0`
            }

            if (ref === 'bingo_call_list') {
                return `anchor-${ref}`
            }

            return `anchor-${ref.substr(8)}`
        },
        async handleAddItemEvent() {
            if (this.currentWidget.focusedItem === this.item || this.currentWidget.openHeader) {
                await this.addItem()
            }
        },
        setItemOptions() {
            this.activities = this.documentTypes.map((element) => ({
                key: element.key,
                name: element.name,
            }))

            this.options = [
                { group: 'Activities', items: this.activities },
                { group: 'Elements', items: element_types },
            ]
        },
        async handleItemSelect(item) {
            this.selectedItem = item
            await this.addItem()
        },
        openAddItemModal() {
            EventBus.$emit('open-add-item-modal', this.handleItemSelect)
        },
    },
}
</script>

<style lang="scss" scoped>
@import 'Scss/base-worksheets.scss';

.widget-drawer {
    background-color: $gray-800;
    border: 1px solid $gray-400;
    height: 44px;

    .add-item-label {
        width: 86%;
        color: $gray-900;
        &:hover {
            color: $black;
            background-color: $primary-100;
        }
    }

    .hover {
        color: $black;
        background-color: $primary-100;
    }

    .add-item-dropdown-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 31px;
        color: $gray-800;
        background-color: $gray-300;
        &:focus {
            box-shadow: none;
        }
        &:hover,
        &:active {
            color: $gray-900;
            background-color: $gray-300;
        }
    }
}
</style>
