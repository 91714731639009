<template>
    <div class="inline-image-btn">
        <b-button
            class="py-0 px-2 d-flex align-items-center justify-content-center image-btn w-100 h-100"
            :class="[{ 'has-inline-image': hasInlineImage, 'no-inline-image': !hasInlineImage }, btnClass]"
            variant="outline-primary"
            @click="handleButtonClick"
        >
            <template v-if="hasInlineImage">
                <img
                    v-if="hasImage"
                    :key="url"
                    :src="getImageLink"
                    width="25"
                    class="inline-image-icon"
                    @error="imageError = true"
                />
                <b-spinner
                    v-else-if="!hasImage"
                    variant="primary"
                    aria-hidden="true"
                    style="width: 20px; height: 20px"
                ></b-spinner>
            </template>
            <b-icon v-else icon="image" class="image-icon" variant="outline-primary" aria-hidden="true" width="25"></b-icon>
        </b-button>
        <ImageControlBar
            v-if="showControlBar"
            v-on-clickaway="handleClickAway"
            is-inline-image
            :is-svg="isSvg"
            :color="color"
            :image-link="getImageLink"
            @add-image="openImageUploader"
            @updateColor="updateColor"
            @saveColor="saveColor"
            @rotate="rotateIcon"
            @flip-horizontal="flipHorizontal"
            @flip-vertical="flipVertical"
            @delete="deleteIcon"
        />
    </div>
</template>

<script>
import InlineImagesMixin from '../mixins/InlineImages'
import ImagesMixin from '../mixins/Images'
import { EventBus } from '../common/EventBus'
import { mixin as clickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'

export default {
    components: {
        ImageControlBar: () => import('./widgets/ImageControlBar.vue'),
    },
    mixins: [InlineImagesMixin, ImagesMixin, clickaway],
    props: {
        imageId: {
            type: String,
            default: null,
        },
        itemIndex: {
            type: Number,
            required: false,
            default: undefined,
        },
        inputIndex: {
            type: Number,
            required: false,
            default: undefined,
        },
        column: {
            type: String,
            required: false,
            default: 'term',
        },
        btnClass: {
            type: [String, Object, Array],
            required: false,
            default: '',
        },
    },
    data() {
        return {
            show: false,
            imageError: false,
        }
    },
    computed: {
        ...mapGetters({
            entityType: 'document/entityType',
            persistAction: 'document/immediatePersistAction',
            isLoggedIn: 'user/isLoggedIn',
        }),
        showControlBar() {
            return this.hasImage && this.show
        },
        properties() {
            return this.getInlineImage(this.imageId)
        },
        shouldLoadSvg() {
            return this.isSvg
        },
        hasInlineImage() {
            return this.properties?.objectId && this.properties?.objectId?.length > 0
        },
        getImageLink() {
            return this.imageError ? 'images/image.svg' : this.btnImageLink
        },
    },
    watch: {
        'properties.objectId': {
            async handler(newValue, oldValue) {
                if (newValue !== oldValue) await this.loadImage()
            },
        },
        showControlBar: {
            handler(newValue) {
                if (newValue) {
                    EventBus.$emit('imageControlBarOpened', this.properties.id)
                } else {
                    EventBus.$emit('imageControlBarClosed', this.properties.id)
                }
            },
        },
        btnImageLink: {
            handler() {
                this.imageError = false
            },
        },
    },
    async mounted() {
        this.is_inline_image = true
        await this.loadImage()
        EventBus.$on('imageControlBarOpened', (id) => {
            if (`${this.properties?.id}` !== `${id}`) {
                this.show = false
            }
        })
    },
    methods: {
        async openImageUploader() {
            this.show = false
            await this.$store.dispatch('document/setInlineImagesUpload', {
                item_index: this.itemIndex,
                input_index: this.inputIndex,
                column: this.column,
            })
            await this.$store.dispatch('document/setShowImageUploader', true)
            await this.$store.dispatch('document/setIsInlineImage', true)
            await this.$store.dispatch('document/setReplaceImageId', this.imageId)
            this.$emit('click')
        },
        async handleButtonClick() {
            if (this.hasImage) {
                this.show = !this.show
            } else {
                await this.openImageUploader()
            }
        },
        handleClickAway() {
            if (this.show) {
                this.show = false
            }
        },
        async deleteIcon() {
            await this.deleteInlineImage()
            this.$emit('deleted')
            this.show = false
            await this.saveDocument()
        },
        async saveDocument() {
            try {
                if (this.isLoggedIn) {
                    await this.$store.dispatch(this.persistAction)
                }
            } catch (error) {
                throw error
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.no-inline-image {
    border-color: #ced4da !important;
    &:hover {
        border-color: #ced4da !important;
    }
}
.image-btn {
    min-width: 43px;
    font-size: 1rem;
    line-height: 1rem;
}
.preloader-spinner,
.preloader {
    max-width: 50px;
    max-height: 50px;
}
.image-icon {
    background: none;
    border: none;
    padding: 0;
    .img {
        display: block;
    }
}
.has-inline-image {
    border-style: dashed;
}

.resource-box {
    position: absolute;
    bottom: -2.5px;
    right: 45px;
    height: 45px;
    max-height: 50px;
    z-index: 1000;
}
</style>
