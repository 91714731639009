<template>
    <div class="image-uploader">
        <div class="d-flex justify-content-end">
            <button
                class="btn btn-primary rounded btn-sm d-flex align-items-center"
                :disabled="isUploadingImage"
                @click="openImageUploader"
            >
                Add Sticker
                <svg class="ml-2" width="21" height="16" viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.89552 0H0V6.76923H4.89552V0Z" fill="white" />
                    <path d="M6.91496 1.69231H10.709V4.23077H6.91496V1.69231Z" fill="white" />
                    <path d="M14.503 1.69231H18.297V4.23077H14.503V1.69231Z" fill="white" />
                    <path d="M22.091 1.69231H25.8851V4.23077H22.091V1.69231Z" fill="white" />
                    <path d="M29.6791 1.69231H33.4731V4.23077H29.6791V1.69231Z" fill="white" />
                    <path d="M39.1642 9.09615V14.0321H37.3284V9.09615H39.1642Z" fill="white" />
                    <path d="M39.1642 18.9679V23.9038H37.3284V18.9679H39.1642Z" fill="white" />
                    <path d="M33.4731 31.3077H29.6791V28.7692H33.4731V31.3077Z" fill="white" />
                    <path d="M25.8851 31.3077H22.091V28.7692H25.8851V31.3077Z" fill="white" />
                    <path d="M18.297 31.3077H14.503V28.7692H18.297V31.3077Z" fill="white" />
                    <path d="M10.709 31.3077H6.91496V28.7692H10.709V31.3077Z" fill="white" />
                    <path d="M1.22388 23.9038V18.9679H3.0597V23.9038H1.22388Z" fill="white" />
                    <path d="M1.22388 14.0321V9.09615H3.0597V14.0321H1.22388Z" fill="white" />
                    <path d="M0 26.2308H4.89552V33H0V26.2308Z" fill="white" />
                    <path d="M41 26.2308H36.1045V33H41V26.2308Z" fill="white" />
                    <path d="M36.1045 0H41V6.76923H36.1045V0Z" fill="white" />
                    <path
                        d="M24.2907 10.1538L32.4328 21.9447H9.1791L14.6703 13.7818L18.1813 19.001L24.2907 10.1538Z"
                        fill="white"
                    />
                </svg>
            </button>
        </div>

        <div
            v-if="show"
            v-click-outside="close"
            class="position-absolute border-blue-left body-style-settings bg-white f-14 rounded shadow"
            style="max-height: 90vh"
        >
            <div class="position-relative pb-2 pt-3 px-2 d-flex flex-wrap flex-column">
                <svg
                    class="text-muted position-absolute pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click="close"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <div class="w-100">
                    <div>
                        <h3 class="f-15 font-weight-bold text-center">
                            {{ isInlineImage ? 'Add an Image' : 'Add a Sticker' }}
                        </h3>
                        <div class="d-flex justify-content-center my-2">
                            <div class="d-flex flex-column position-relative" style="flex: 1">
                                <PremiumMarker
                                    v-if="!hasFeature('image', document, document.entity_type)"
                                    class="position-absolute"
                                    style="right: 3px"
                                >
                                    <p>Go premium to get access to ALL available icons and images.</p>
                                </PremiumMarker>
                                <FileDropZone :crop="false" :max-file-size="5242880" @selected="imageSelected" />
                            </div>
                        </div>
                    </div>
                    <div @mouseenter="openIconsGallery">
                        <div class="d-flex pb-2 align-items-center">
                            <div class="flex-grow-1 border-bottom"></div>
                            <span class="px-2 border rounded-xl">Search Library</span>
                            <div class="flex-grow-1 border-bottom"></div>
                        </div>

                        <div class="input-group">
                            <input
                                ref="searchInput"
                                v-model.trim="search"
                                class="form-control form-control-sm italics-placeholder rounded-0 border-dark"
                                placeholder="Sun, rectangle, pie chart, stars, etc."
                                type="text"
                                @focus="focusSearchInput"
                                @blur="searchFocused = false"
                                @keyup.enter="addSearchToLocalHistory"
                                @input="updateTypingStatus"
                            />
                            <div class="input-group-append">
                                <button
                                    v-b-tooltip.hover
                                    class="btn btn-primary px-3 btn-sm text-sm rounded-0"
                                    type="button"
                                    :title="search ? 'Clear Search' : 'Search'"
                                    @click="
                                        search = ''
                                        $refs.searchInput.focus()
                                    "
                                >
                                    <b-icon :icon="search ? 'x' : 'search'"></b-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="icons-api w-full">
                            <div class="overflow-y-auto primary-scroll-bar pt-3 pb-2 w-full d-flex scroll flipped no-wrap">
                                <!-- TODO: Implement correct tag functionality                              -->
                                <!--                                <span-->
                                <!--                                    v-for="(item, index) in tags"-->
                                <!--                                    :key="index"-->
                                <!--                                    class="border px-2 cursor-pointer mx-1 rounded d-flex align-items-center"-->
                                <!--                                    :class="selectedTag === item ? 'btn-primary' : ''"-->
                                <!--                                    @click="toggleTag(item)"-->
                                <!--                                >-->
                                <!--                                    {{ item }}-->
                                <!--                                    <b-icon class="pl-1" title="Remove Tag" :icon="'x'" @click="removeItem(item)"></b-icon>-->
                                <!--                                </span>-->
                            </div>
                            <div id="icons-gallery" class="mt-3 icons-gallery">
                                <InfiniteListComponent class="max-h-40vh w-100" @load-more="loadMoreIcons">
                                    <div class="d-flex flex-wrap">
                                        <div
                                            v-for="(icon, index) in icons"
                                            :key="index"
                                            class="cursor-pointer icon-container position-relative pb"
                                            :class="{ pr: (index + 1) % 3 !== 0 }"
                                            @click="toggleSelectedIcon(icon)"
                                        >
                                            <PremiumMarker
                                                v-if="
                                                    isLoggedIn &&
                                                    !icon.is_default &&
                                                    icon.our_premium &&
                                                    !hasFeature('image', document, document.entity_type)
                                                "
                                                class="position-absolute"
                                                boundary="viewport"
                                                style="right: 3px; display: block"
                                            >
                                                <p>
                                                    This is a premium image. Go premium to get access to ALL available icons
                                                    and images.
                                                </p>
                                            </PremiumMarker>
                                            <VueLoadImage class="icon">
                                                <img slot="image" :src="icon.thumbnail_url" alt="Image 2" />
                                                <template #preloader>
                                                    <ImageSpinner show-pre-loader />
                                                </template>
                                                <ImageLoadError slot="error" width="120" height="120" />
                                            </VueLoadImage>
                                        </div>
                                    </div>
                                    <div v-if="iconsSearching" class="w-100 d-flex justify-content-center">
                                        <div class="spinner-border justify-content-center" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </InfiniteListComponent>
                            </div>
                            <div class="d-flex justify-content-end mt-2">
                                <div v-if="!userTyping && !iconsSearching">
                                    <p class="text-sm m-0">
                                        <span v-if="search">{{ iconsTotalCount }} results for '{{ search }}'</span>
                                        <span v-else>Popular results</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { debounce } from 'lodash'
import ImageModel from '../../store/models/Image'
import ImageUploadApi from '../../apis/ImageUploadApi'
import EditPayWall from '../../mixins/EditPayWall'
import { dangerToast } from '../../helpers/toasts'
import ClickOutside from '../../directives/click-outside'

export default {
    name: 'ImageUploader',
    components: {
        ImageLoadError: () => import('./ImageLoadError.vue'),
        ImageSpinner: () => import('./ImageSpinner.vue'),
        FileDropZone: () => import('../FileDropZone.vue'),
        VueLoadImage: () => import('vue-load-image'),
        InfiniteListComponent: () => import('../InfiniteListComponent.vue'),
    },
    directives: { ClickOutside },
    mixins: [EditPayWall],
    data() {
        return {
            show: false,
            search: '',
            pageCount: 32,
            searchFocused: false,
            keepIconsGalleryOpen: false,
            selectedTag: null,
            tags: [],
            userTyping: false,
            used_icon_feature: false,
            isUploadingImage: false,
        }
    },
    computed: {
        ...mapState(['document', 'user']),
        ...mapGetters({
            style: 'document/documentStyle',
            worksheetImages: 'document/worksheetImages',
            flashcardImages: 'document/flashcardImages',
            bingoImages: 'document/bingoImages',
            documentWidth: 'document/documentWidth',
            entityType: 'document/entityType',
            persistAction: 'document/immediatePersistAction',
            isLoggedIn: 'user/isLoggedIn',
            icons: 'iconLibrary/icons',
            iconsSearching: 'iconLibrary/loading',
            iconsTotalCount: 'iconLibrary/totalIcons',
            defaultIcons: 'iconLibrary/defaultIcons',
            showImageUploader: 'document/showImageUploader',
            isInlineImage: 'document/isInlineImage',
            currentWidget: 'document/currentWidget',
            bingoItems: 'document/bingoItems',
            flashcardItems: 'document/flashcardItems',
            worksheetItems: 'document/worksheetItems',
        }),
        images() {
            if (this.entityType === 'worksheet') return this.worksheetImages
            if (this.entityType === 'flashcard') return this.flashcardImages
            if (this.entityType === 'bingo') return this.bingoImages
            return []
        },
    },
    watch: {
        async search(newVal) {
            !newVal ? await this.toggleInitialIcons() : await this.searchIcons()
        },
        showImageUploader() {
            this.toggleVisible()
        },
        show(val) {
            this.$store.dispatch('document/setIsImageUploaderOpen', val)
        },
    },
    async mounted() {
        this.loadTags()
        await this.checkGalleryState()
    },
    methods: {
        ...mapActions('iconLibrary', ['getIcons']),
        ...mapActions('document', ['setLoadingImage']),
        async toggleVisible() {
            this.show = !this.show
            this.$emit('visible-changed', this.show)
            this.preLoadSearch()

            this.used_icon_feature = localStorage.getItem('used-icon-finder-feature')

            await this.checkGalleryState()
            this.$refs.searchInput.select()

            if (!this.used_icon_feature) {
                setTimeout(() => {
                    this.used_icon_feature = true
                    localStorage.setItem('used-icon-finder-feature', true)
                }, 2500)
            }
        },
        async close() {
            this.closeIconsGallery()
            this.show = false
            this.$emit('visible-changed', this.show)
            await this.$store.dispatch('document/closeImageUploader')
        },
        async checkGalleryState() {
            if (!this.used_icon_feature) {
                await this.getIcons(this.defaultIcons)
                this.openIconsGallery()
            }
        },
        async imageSelected(files) {
            this.isUploadingImage = true
            try {
                this.setLoadingImage(!this.isInlineImage)
                const pages = document.getElementsByClassName('render-page')
                const attachments = Array.from(files).map((f) => {
                    let original = f
                    const docIndex = this.currentDocumentInViewport(pages)
                    let file = new ImageModel(f.name, docIndex)
                    Promise.all([this.loadImageProperties(f), ImageUploadApi.upload(original)])
                        .then(([{ width, height }, { id, thumb }]) => {
                            file.thumb = thumb

                            if (width > file.width) {
                                file.height = file.width / (width / height)
                            } else {
                                file.height = height
                                file.width = width
                            }
                            file.x = 300
                            file.y = 300
                            file.objectId = id
                            file.ratio = file.width / file.height

                            this.$nextTick(() => (file.lockAspectRatio = true))
                            file.uploading = false
                            this.updateFile(file)
                            this.setLoadingImage(false)
                        })
                        .catch((error) => {
                            console.error(error)
                            this.$bvToast.toast(
                                error.response.data.errors?.image[0] ?? 'Sorry! an error occured',
                                dangerToast,
                            )
                            this.removeAttachmentFromDocument(file.id)
                        })
                    return file
                })
                await this.addNewAttachmentToDocument(attachments)
                this.close()
            } finally {
                this.isUploadingImage = false
            }
        },
        async addNewAttachmentToDocument(attachments) {
            if (!attachments || attachments.length === 0) return

            for (let i = 0; i < attachments.length; i++) {
                if (!attachments[i].height) attachments[i].height = attachments[i].width * attachments[i].ratio
                attachments[i].y = 300
                attachments[i].x = 300
                attachments[i].ratio = true
                attachments[i].lockAspectRatio = true
                attachments[i].entity = this.entityType
            }
            await this.$store.dispatch('document/setImage', attachments)
            this.removeSearchPair()
            this.addSearchPairToLocalHistory(attachments[0].id)
            this.search = ''
        },
        async updateDocumentIconImageId(payload) {
            payload.entity = this.entityType
            await this.$store.dispatch('document/setImageId', payload)
        },
        currentDocumentInViewport(pages = null) {
            if (pages == null) {
                pages = document.getElementsByClassName('render-page')
            }
            let maxViewableHeight = 0
            let index = 0

            for (let i = 0; i < pages.length; i++) {
                const viewableHeight = this.calcViewableHeightOf(pages[i])

                if (maxViewableHeight === 0) {
                    maxViewableHeight = viewableHeight
                    index = i
                    continue
                }

                if (viewableHeight > maxViewableHeight) return i
            }

            return index
        },
        calcViewableHeightOf(page) {
            const pageRect = page.getBoundingClientRect()
            if (pageRect.top < 0 && pageRect.bottom < 0) {
                return 0
            } else if (pageRect.top > 0) {
                return Math.min(pageRect.bottom, window.innerHeight) - pageRect.top
            } else {
                return Math.min(pageRect.bottom, window.innerHeight)
            }
        },
        loadImageProperties(file) {
            return new Promise((resolve) => {
                const fr = new FileReader()

                fr.onload = function () {
                    const img = new Image()
                    img.onload = function () {
                        resolve({
                            width: img.width,
                            height: img.height,
                        })
                    }

                    img.src = fr.result
                }

                fr.readAsDataURL(file)
            })
        },
        async updateFile(file, persist = true) {
            file.entity = this.entityType
            await this.$store.dispatch('document/updateImage', file)
            if (persist) {
                this.$store.dispatch('document/requestUpdateDocument')

                if (this.isLoggedIn) {
                    await this.$store.dispatch(this.persistAction)
                }
            }
        },
        toggleTag(tag) {
            this.updateTypingStatus()
            this.selectedTag = this.search === tag ? null : tag
            this.search = this.search === tag ? '' : tag
        },
        async toggleInitialIcons() {
            const search = !this.search ? this.defaultIcons : this.search
            await this.getIcons(search)
        },
        openIconsGallery() {
            this.keepIconsGalleryOpen = true
        },
        closeIconsGallery() {
            this.keepIconsGalleryOpen = false
        },
        searchIcons: debounce(async function () {
            const currentSearch = this.search

            if (!currentSearch) return
            await this.$nextTick()

            if (currentSearch !== this.search) return

            await this.getIcons(currentSearch)
        }, 500),
        async loadMoreIcons() {
            await this.getIcons()
        },
        async toggleSelectedIcon(icon) {
            this.isUploadingImage = true
            try {
                const docIndex = this.currentDocumentInViewport()
                const file = new ImageModel(this.search || this.defaultIcons, docIndex, icon)

                await this.addNewAttachmentToDocument([{ ...file }])

                this.close()

                const oldId = file.objectId
                const id = await ImageUploadApi.downloadIcon(icon)
                await this.updateDocumentIconImageId({ oldId, newId: id })
            } finally {
                this.isUploadingImage = false
            }
        },
        async focusSearchInput() {
            this.searchFocused = true
            if (this.icons?.length) return

            if (!this.search) {
                await this.toggleInitialIcons()
                return
            }

            await this.searchIcons()
        },
        addSearchPairToLocalHistory(imageId) {
            if (!this.search) return
            let previous = localStorage.getItem('search_pair')
            let items = previous ? JSON.parse(previous) : []
            let hasSearchTerm = items.find((item) => item.searchTerm === this.search)
            let hasImageId = items.find((item) => item.imageId === imageId)
            if (hasSearchTerm || hasImageId) {
                hasSearchTerm ? (hasSearchTerm.imageId = imageId) : (hasImageId.searchTerm = this.search)
                localStorage.setItem('search_pair', JSON.stringify(items))
                return
            }
            items.push({
                searchTerm: this.search,
                imageId: imageId,
            })
            localStorage.setItem('search_pair', JSON.stringify(items))
        },
        getSearchPair(imageId) {
            let terms = localStorage.getItem('search_pair')
            let items = terms ? JSON.parse(terms) : []
            let searchPair = items.find((item) => item.imageId === imageId)
            return searchPair ? searchPair.searchTerm : ''
        },
        removeSearchPair() {
            const imageId = this.document.replaceImageId
            let terms = localStorage.getItem('search_pair')
            let items = terms ? JSON.parse(terms) : []
            let searchPair = items.find((item) => item.imageId === imageId)
            if (searchPair) {
                items = items.filter((item) => item.imageId !== imageId)
            }
            localStorage.setItem('search_pair', JSON.stringify(items))
        },
        addSearchToLocalHistory() {
            if (!this.search) return
            let previous = localStorage.getItem('iconfinder_history')
            let items = previous ? JSON.parse(previous) : []
            if (!items.includes(this.search)) {
                items.push(this.search)
                localStorage.setItem('iconfinder_history', JSON.stringify(items))
            }

            this.selectedTag = this.search
            this.tags = items
        },
        loadTags() {
            let previous = localStorage.getItem('iconfinder_history')
            this.tags = previous ? JSON.parse(previous) : []
        },
        removeItem(item) {
            let items = localStorage.getItem('iconfinder_history')
            if (items) {
                items = JSON.parse(items)
                items.forEach((value, index) => {
                    if (value === item) {
                        items.splice(index, 1)
                    }
                })
            }
            localStorage.setItem('iconfinder_history', JSON.stringify(items))
            this.tags = items
        },
        updateTypingStatus() {
            this.userTyping = true
            if (this.timer) clearTimeout(this.timer)

            this.timer = setTimeout(() => {
                this.userTyping = false
            }, 1000)
        },
        async openImageUploader() {
            await this.$store.dispatch('document/setShowImageUploader', true)
            await this.$store.dispatch('document/setIsInlineImage', false)
        },
        preLoadSearch() {
            if (!this.isInlineImage) return
            if (this.document.replaceImageId) {
                this.search = this.getSearchPair(this.document.replaceImageId)
                this.addSearchToLocalHistory()
                return
            }
            const value = this.document.inline_images_upload.column
            const key = this.document.inline_images_upload.input_index

            const searchItem = this.currentWidget.focusedItem
                ? this.worksheetItems[this.currentWidget.focusedItem.display_order].data.pairs[key]
                : this.flashcardItems[key]

            if (searchItem && !this.currentWidget.openBingoWords) {
                if (searchItem[value]) {
                    this.search = this.cleanAndLimitSearch(searchItem[value])
                    this.addSearchToLocalHistory()
                    return
                }
                const pairValue = value === 'term' ? 'definition' : 'term'
                this.search = this.cleanAndLimitSearch(searchItem[pairValue])
                this.addSearchToLocalHistory()
                return
            }
            if (this.currentWidget.openBingoWords && this.bingoItems[key] && this.bingoItems[key][value]) {
                this.search = this.cleanAndLimitSearch(this.bingoItems[key][value])
                this.addSearchToLocalHistory()
            }
        },
        cleanAndLimitSearch(searchTerm) {
            if (!searchTerm) return ''
            const cleanedSearchTerm = searchTerm.replace(/<\/?[^>]+(>|$)/g, '')
            return cleanedSearchTerm.split(' ').slice(0, 5).join(' ')
        },
    },
}
</script>

<style scoped>
.rounded-xl {
    border-radius: 50px;
}

.overflow-y-auto {
    overflow-y: auto;
}

.max-h-40vh {
    max-height: 40vh;
}

.icons-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    min-height: 200px;
}

.icons-gallery .icon-container {
    width: 32%;
}

.icon-container.pb {
    padding-bottom: 4px;
}
.icon-container.pr {
    padding-right: 4px;
}

.icon-container .icon {
    width: 100%;
}

.icon-container .icon img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

.filter-component label {
    display: block;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.no-wrap {
    white-space: nowrap;
}
</style>
